.container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  position: relative;
}
.holder {
  margin-left: 10rem;
  margin-right: 10rem;
}
.imageHolder {
  position: absolute;
  bottom: 0px;
  width: 100%;
  opacity: 0.3;
}
.imageHolder > img {
  width: 100%;
}
.content {
  margin-bottom: 10px;
}
