body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: unset;
  line-height: unset;
  font-size: unset;
}
html {
  font-size: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --rc-shell-hr-divider: #ff694f;
  --rc-shell-button-bg: #ff694f;
  --rc-shell-button-rollover: #da452a;
  --rc-shell-button-selected: #9e3004;
  --rc-shell-button-disabled-bg: #a7aab3;
  --rc-shell-video-title-bg: #ff694f;
  --rc-shell-video-subtitle-bg: #58565630;
  --rc-shell-seekbar-knobby: #ff694f;
  --rc-shell-seekbar-bg: #cccfcf;
  --rc-shell-seek-before: #f59992;
  --rc-shell-knobby-selected: #9e3004;
  --rc-shell-play-pause-bg: #ffffff40;
  --rc-shell-navigation-bar-bg: #f7e4e3;
  --rc-shell-stage-bg: white;
  --rc-shell-toc-bg: #f7e4e3;
  --rc-shell-toc-logo-bg: #ff694f;
  --rc-shell-button-launch: #c8cacc;
  --rc-shell-instruction: #ff694f;
  --rc-shell-title: #ff694f;
  --rc-shell-loader-dots: #ff694f;
  --rc-shell-breadcrumb-link: #66605f;
  --rc-shell-breadcrumb-active: #000000;
  --rc-shell-breadcrumb-divider: #ff694f;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(189, 189, 189);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(189, 189, 189);
}
*,
:after,
:before {
  box-sizing: unset;
}
