.container {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  padding: 25px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.header {
  font-size: unset !important;
}
.instruction {
  margin-bottom: 10px;
}
.note {
  margin-bottom: 10px;
}
.tiles {
  flex: 1;
}
.innerStage {
  flex: 1;
  display: flex;
  align-items: center;
}
.innerStageLeft {
  width: 35%;
  height: calc(100% - 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.innerStageRight {
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttons {
  font-size: 15px !important;
  width: calc(100% - 10px);
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: var(--rc-shell-button-bg);
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: honeydew;
  font-weight: 400;
  user-select: none;
  cursor: pointer;
}
.buttonsSelected {
  background-color: var(--rc-shell-button-selected);
}
.buttons:hover {
  background-color: var(--rc-shell-button-rollover);
}
.buttonsSelected:hover {
  background-color: var(--rc-shell-button-rollover);
}

.stepImage {
  position: absolute;
}
.explanation {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}
