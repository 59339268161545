.container {
  display: flex;
  background-color: white;
  overflow: hidden;
}
.tocHolder {
  height: 100%;
  background-color: var(--rc-shell-toc-bg);
  overflow: overlay;
}
.stage {
  height: 100%;
  background-color: var(--rc-shell-stage-bg);
  position: relative;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.image {
  max-height: 100%;
  max-width: 100%;
}
.navigationHolder {
  width: 100%;
  height: 35px;
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
}

.logoBg {
  height: 150px;
  background-color: var(--rc-shell-toc-logo-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  margin-bottom: 10px;
}
.logoBg > img {
  height: 100%;
}
.treeHolder {
  margin-left: 10px;
}
.breadcrumbHolder {
  width: 100%;
  height: 25px;
  background-color: var(--rc-shell-toc-logo-bg);
}
.stageContentHolder {
  height: calc(100% - 59px);
  overflow-y: overlay;
  overflow-x: hidden;
}
.breadcrumbText {
  margin-left: 10px;
  font-size: medium;
}
.breadcrumbHolderShadow {
  width: 100%;
  height: 24px;
  background-color: gainsboro;
}
