.progressHolder {
  width: 100%;
  height: 0;
  /* background-color: red; */
  /* margin-bottom: 0.4rem; */
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actionHolder {
  width: 100%;
  background-color: var(--rc-shell-navigation-bar-bg);
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.actionPanel {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0;
}
.actionPanel2 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.buttonPanel {
  flex: 1 0;
  width: 2rem;
  height: 100%;
  background-color: var(--rc-shell-button-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  cursor: pointer;
  flex-wrap: wrap;
}
.buttonPanel[disabled] {
  background-color: var(--rc-shell-button-disabled-bg);
  cursor: default;
}
.volumeSlider {
  flex: 1 0;
  width: 0;
  background-color: lightyellow;
  /* bottom: 2rem; */
  position: absolute;
  /* height: 120px; */
  display: flex;
  justify-content: center;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  bottom: 100px;
}
.indicator {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 0.85rem;
  font-family: monospace;
  user-select: none;
}

.volumeBar {
  --bar-bg: var(--rc-shell-seekbar-bg);
  --seek-before-width: 0;
  --seek-before-color: var(--rc-shell-seek-before);
  --knobby: var(--rc-shell-seekbar-knobby);
  --selectedKnobby: var(--rc-shell-knobby-selected);
  --bar-height: 11px;
  appearance: none;
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  height: var(--bar-height);
  outline: none;
  /* bottom: 17px; */
  margin: 0;
}
/* seek bar - safari*/
.volumeBar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

/* seek bar - firefox*/
.volumeBar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}
.volumeBar::-moz-focus-outer {
  border: none;
}
/* seek bar - chrome and safari*/
.volumeBar::before {
  content: "";
  height: var(--bar-height);
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  position: absolute;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}
/* seek bar - firefox*/
.volumeBar::-moz-range-progress {
  height: var(--bar-height);
  background-color: var(--seek-before-color);
}
/* knobby - chrome and safari*/
.volumeBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: -2px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
  transform: scale(1.2);
}
/* knobby while dragging - chrome and safari*/
.volumeBar:active::-webkit-slider-thumb {
  transform: scale(1.5);
  background: var(--selectedKnobby);
}
/* knobby - firefox*/
.volumeBar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}
/* knobby while dragging - firefox*/
.volumeBar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}

.seekBar {
  --bar-bg: var(--rc-shell-seekbar-bg);
  --seek-before-width: 0;
  --seek-before-color: var(--rc-shell-seek-before);
  --knobby: var(--rc-shell-seekbar-knobby);
  --selectedKnobby: var(--rc-shell-knobby-selected);
  --bar-height: 11px;
  appearance: none;
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
  /*  bottom: 17px; */
  margin: 0;
}
/* seek bar - safari*/
.seekBar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

/* seek bar - firefox*/
.seekBar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}
.seekBar::-moz-focus-outer {
  border: none;
}
/* seek bar - chrome and safari*/
.seekBar::before {
  content: "";
  height: var(--bar-height);
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  position: absolute;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}
/* seek bar - firefox*/
.seekBar::-moz-range-progress {
  height: var(--bar-height);
  background-color: var(--seek-before-color);
}
/* knobby - chrome and safari*/
.seekBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: -2px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
  transform: scale(1.2);
}
/* knobby while dragging - chrome and safari*/
.seekBar:active::-webkit-slider-thumb {
  transform: scale(1.5);
  background: var(--selectedKnobby);
}
/* knobby - firefox*/
.seekBar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}
/* knobby while dragging - firefox*/
.seekBar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}
