.container {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  position: relative;
  margin: 10px;
}
.menuHolder {
  height: 20%;
  flex: 0;
}
.sectionHolder {
  height: 80%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin: 0 !important;
  justify-content: space-around;
}
.singlePara {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  line-height: 22px;
}
.sectionTopHolder {
  margin: 10px;
}
.sectionBottomHolder {
  flex: 1;
  display: flex;
  justify-content: row;
  padding: 20px;
}
.sectionBottomLeftHolder {
  flex: 0.5;
}
.sectionBottomRightHolder {
  flex: 0.5;
}
.title {
  font-weight: 700;
  padding-left: 20px;
}
.imageHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageStage {
  width: 70%;
}

ul {
  list-style-type: square;
}
.hDivider {
  width: 0.5px;
  height: 100%;
  background-color: var(--rc-shell-hr-divider);
}
.tabHolder4 {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.title4 {
  font-weight: 700;
  margin-bottom: 5px;
}
.imageHolderQ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageStageQ {
  width: 70%;
}
.buttonHolder {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
