/*video*/
.playerWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  --bar-bg: var(--rc-shell-seekbar-bg);
  --seek-before-width: 0;
  --seek-before-color: var(--rc-shell-seek-before);
  --knobby: var(--rc-shell-seekbar-knobby);
  --selectedKnobby: var(--rc-shell-knobby-selected);
  --bar-height: 11px;
  --button-bg: var(--rc-shell-button-bg);
  --title-bg: var(--rc-shell-video-title-bg);
  --subTitle-bg: var(--rc-shell-video-subtitle-bg);
}

.controlsWrapper {
  opacity: 0.01;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: #cecaca30;
  display: flex;
  flex-direction: column-reverse;
  margin-top: -4px;
}

.elementHolder {
  display: flex;
  width: 100%;
  /* margin-bottom: 4px; */
  align-items: center;
}
.playerTitle {
  height: 40px;
  background-color: var(--title-bg);
  color: white;
  font-family: monospace;
  font-size: 18px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
}
.playerTitle > p {
  padding-left: 10px;
}
.playerSubTitle {
  height: 25px;
  background-color: var(--subTitle-bg);
  color: white;
  font-family: monospace;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 40px;
  width: 100%;
}
.playerSubTitle > p {
  padding-left: 10px;
}
.buttonCenterHolder {
  position: absolute;
  width: 20rem;
  height: 5rem;
  left: calc(50% - 10rem);
  top: calc(50% - 2rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttonPlayPausePanel {
  width: 5rem;
  height: 5rem;
  background-color: var(--rc-shell-play-pause-bg);
  border-radius: 50%;
  color: rgb(0, 0, 0);
  cursor: pointer;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  box-shadow: 3px 3px grey;
}
.buttonFFRewindPanel {
  width: 3.5rem;
  height: 3.5rem;
  background-color: var(--rc-shell-play-pause-bg);
  border-radius: 50%;
  color: rgb(0, 0, 0);
  cursor: pointer;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  box-shadow: 3px 3px grey;
}
.buttonPanel {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  background-color: var(--button-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  cursor: pointer;
  flex-wrap: wrap;
}
.timerPanel {
  width: 150px;
  height: 2.5rem;
  background-color: var(--button-bg);
  color: white;
  font-family: monospace;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.volumePanel {
  display: flex;
  justify-content: center;
  position: relative;
}
.volumeBar {
  position: absolute;
  transform: rotate(-90deg);
  bottom: 100px;
  margin: 0;
  appearance: none;
  background: var(--bar-bg);
  border-radius: 30px;
  height: var(--bar-height);
  outline: none;
  margin-left: 10px;
  margin-right: 10px;
}
/* seek bar - safari*/
.volumeBar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

/* seek bar - firefox*/
.volumeBar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}
.volumeBar::-moz-focus-outer {
  border: none;
}
/* seek bar - chrome and safari*/
.volumeBar::before {
  content: "";
  height: var(--bar-height);
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  border-radius: 30px;
}
/* seek bar - firefox*/
.volumeBar::-moz-range-progress {
  height: var(--bar-height);
  background-color: var(--seek-before-color);
}
/* knobby - chrome and safari*/
.volumeBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: -2px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
}
/* knobby while dragging - chrome and safari*/
.volumeBar:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}
/* knobby - firefox*/
.volumeBar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}
/* knobby while dragging - firefox*/
.volumeBar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}

.seekBar {
  appearance: none;
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
  margin-left: 10px;
  margin-right: 10px;
}
/* seek bar - safari*/
.seekBar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

/* seek bar - firefox*/
.seekBar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}
.seekBar::-moz-focus-outer {
  border: none;
}
/* seek bar - chrome and safari*/
.seekBar::before {
  content: "";
  height: var(--bar-height);
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  /*  border-radius: 30px; */
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}
/* seek bar - firefox*/
.seekBar::-moz-range-progress {
  height: var(--bar-height);
  background-color: var(--seek-before-color);
}
/* knobby - chrome and safari*/
.seekBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: -2px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
  transform: scale(1.2);
}
/* knobby while dragging - chrome and safari*/
.seekBar:active::-webkit-slider-thumb {
  transform: scale(1.3);
  background: var(--selectedKnobby);
}
/* knobby - firefox*/
.seekBar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}
/* knobby while dragging - firefox*/
.seekBar:active::-moz-range-thumb {
  transform: scale(1.3);
  background: var(--selectedKnobby);
}
