.dTreeContainer {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dTreeNode {
  padding: 0.15rem 1.25rem;
  display: block;
}
.dTreeToggler {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -20px;
  cursor: pointer;
}
.dTreeTogglerActive {
  transform: rotate(90deg);
  position: absolute;
  left: -20px;
  cursor: pointer;
}
.dTreeHead {
  display: inline-block;
  user-select: none;
  cursor: pointer;
  font-size: smaller;
}
.dTreeNode > div {
  display: flex;
}
.dTreeElementHolder {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
