.container {
  width: calc(100% - 100px);
  height: calc(100% - 50px);
  padding: 25px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.header {
  font-size: unset !important;
}
.instruction {
  height: 20%;
  margin-top: 20px;
  flex: 1;
}
.tiles {
  flex: 1;
}
