.container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: left;
  position: relative;
}
.leftHolder {
  width: calc(40% - 40px);
  height: calc(100% - 40px);
  display: flex;
  margin: 20px;
  flex: 0;
}

.rightHolder {
  height: calc(100% - 40px);
  margin: 20px;
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 0;
  flex-wrap: wrap;
}
.menuHolder {
  height: 20%;
  flex: 0;
}
.sectionHolder {
  height: 80%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin: 0 !important;
}
.sectionTopHolder {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionBottomHolder {
  height: 50%;
  width: 100%;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
}
.image {
  max-height: 100%;
}
.description {
  margin-bottom: 10px;
}
.buttonHolder {
  display: flex;
  justify-content: center;
}
.scene {
  width: calc(50% - 10px);
  height: 50%;
  perspective: 600px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.flipped {
  transform: rotateY(180deg);
}

.cardFaceFront {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid grey;
}

.cardFaceBack {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid grey;
  background-color: white;
}
.imageHolder {
  width: 100%;
  display: flex;
}

.imageHolder > img {
  width: 100%;
}
.textContent {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 5px;
}

.buttonHolder {
  display: flex;
  justify-content: center;
}
