.container {
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  padding: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.header {
  font-size: unset !important;
}
.tiles {
  flex: 1;
}
.innerStage {
  flex: 1;
  display: flex;
  align-items: center;
}
.innerStageLeft {
  width: 25%;
  height: calc(100% - 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.innerStageRight {
  flex: 1;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.buttons {
  font-size: 15px !important;
  width: calc(100% - 10px);
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: var(--rc-shell-button-bg);
  height: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: honeydew;
  font-weight: 400;
  user-select: none;
  cursor: pointer;
  flex-direction: column;
}
.buttonsSelected {
  background-color: var(--rc-shell-button-selected);
}
.buttons:hover {
  background-color: var(--rc-shell-button-rollover);
}
.buttonsSelected:hover {
  background-color: var(--rc-shell-button-rollover);
}

.stepImage {
  flex: 1;
}
.explanation {
  width: 100%;
}
.title {
  margin-top: 10px;
  width: 100%;
  font-weight: 700;
}
.butText {
  position: absolute;
}
