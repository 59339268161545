.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.holder {
  width: 705px;
  height: 500px;
  display: flex;
  flex-direction: column;
  transform: scale(0.85);
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
.imageHolder1 {
  cursor: pointer;
  position: relative;
  animation-name: animation1;
  animation-duration: 0.5s;
}
@keyframes animation1 {
  0% {
    left: 1000px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

.imageHolder2 {
  cursor: pointer;
  position: relative;
  animation-name: animation2;
  animation-duration: 0.75s;
}
@keyframes animation2 {
  0% {
    left: 1000px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
.imageHolder3 {
  cursor: pointer;
  position: relative;
  animation-name: animation3;
  animation-duration: 1s;
}
@keyframes animation3 {
  0% {
    left: 1000px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
.imageHolder4 {
  cursor: pointer;
  position: relative;
  animation-name: animation4;
  animation-duration: 1.25s;
}
@keyframes animation4 {
  0% {
    left: 1000px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
.imageHolder5 {
  cursor: pointer;
  position: relative;
  animation-name: animation5;
  animation-duration: 1.5s;
}
@keyframes animation5 {
  0% {
    left: 1000px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
