.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  flex-direction: row;
}
.launchPanel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonPanel {
  height: 2rem;
  padding: 10px;
  margin: 10px;
  background-color: var(--rc-shell-button-launch);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #000;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  cursor: pointer;
  flex-wrap: wrap;
  box-shadow: 3px 3px #2b2b2b;
  align-self: flex-start;
}
.videoHolder {
  flex: 1;
  display: flex;
}
.introHolder {
  width: 100%;
  height: 100%;
  display: flex;
}
.introBanner {
  width: 65%;
  height: 100%;
}
.imageHolder {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.introLauncher {
  width: 35%;
  height: 100%;
  background-color: var(--rc-shell-button-bg);
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  padding: 25px;
}
.title {
  font-size: 4rem;
}
.subTitle {
  font-size: 1.5rem;
  justify-content: left;
}
