.container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: left;
  position: relative;
}
.leftHolder {
  width: calc(40% - 40px);
  height: calc(100% - 40px);
  display: flex;
  margin: 20px;
  flex: 0;
}

.rightHolder {
  height: calc(100% - 40px);
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 0;
  justify-content: center;
  align-items: center;
}
.menuHolder {
  height: 20%;
  flex: 0;
}
.sectionHolder {
  height: 80%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin: 0 !important;
}
.sectionTopHolder {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionBottomHolder {
  height: 50%;
  width: 100%;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
}
.imageHolder {
  display: flex;
  justify-content: center;
}
.image {
}
.image > img {
  width: 100%;
}
.description {
  margin-bottom: 10px;
}
.buttonHolder {
  margin-top: 50px;
}

.scene {
  /*  border: 1px solid #ccc; */
  position: relative;
  width: 500px;
  height: 50%;
  perspective: 1000px;
}

.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateZ(-288px);
  transform-style: preserve-3d;
  transition: transform 1s;
}

.carousel__cell {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0.5px solid black;
  /* line-height: 116px;
  font-size: 80px;
  font-weight: bold;
  color: white;
  text-align: center; */
  transition: transform 1s, opacity 1s;
}

.carousel__cell:nth-child(5n + 1) {
  background: hsla(0, 9%, 98%, 0.95);
}
.carousel__cell:nth-child(5n + 2) {
  background: hsla(0, 9%, 98%, 0.95);
}
.carousel__cell:nth-child(5n + 3) {
  background: hsla(0, 9%, 98%, 0.95);
}
.carousel__cell:nth-child(5n + 4) {
  background: hsla(0, 9%, 98%, 0.95);
}
.carousel__cell:nth-child(5n + 5) {
  background: hsla(0, 9%, 98%, 0.95);
}

.carousel__cell:nth-child(1) {
  transform: rotateY(0deg) translateZ(288px);
}
.carousel__cell:nth-child(2) {
  transform: rotateY(72deg) translateZ(288px);
}
.carousel__cell:nth-child(3) {
  transform: rotateY(144deg) translateZ(288px);
}
.carousel__cell:nth-child(4) {
  transform: rotateY(216deg) translateZ(288px);
}
.carousel__cell:nth-child(5) {
  transform: rotateY(288deg) translateZ(288px);
}

.carousel-options {
  text-align: center;
  position: relative;
  z-index: 2;
  background: hsla(0, 0%, 100%, 0.8);
}
.contentHolder {
  display: flex;
}
.contentHolder .image {
  width: 45%;
}
.contentText {
  padding: 10px;
  flex: 1;
}
