.container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}
.questionHolder {
  padding: 20px;
}
.imageHolder {
  display: flex;
  justify-content: space-around;
  margin: 10px;
  width: 100%;
}
.buttonHolder {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.videoHolder {
  width: 70%;
}
.videoWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
