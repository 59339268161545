.button {
  background-color: var(--rc-shell-button-bg);
  display: inline-block;
  padding: 7px;
  color: white;
  cursor: pointer;
  user-select: none;
}
.button:hover {
  background-color: var(--rc-shell-button-rollover);
}
