.container {
  width: calc(100% - 100px);
  height: calc(100% - 50px);
  padding: 25px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.handle {
  width: 15px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
}
.box1 {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgba(218, 201, 105, 0.384);
}
.box2 {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--rc-shell-seek-before);
}
.baseline {
  width: 600px;
  top: 10px;
  pointer-events: none;
}
.sliderHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: relative;
  margin-top: 15px;
  height: 40px;
}
.spots {
  width: 15px;
  height: 15px;
  position: absolute;
  pointer-events: none;
  border-radius: 50px;
  box-shadow: 2px 2px grey;
  background-image: radial-gradient(
    circle,
    var(--rc-shell-button-bg),
    var(--rc-shell-seek-before),
    var(--rc-shell-button-bg)
  );
}
.spotHolder {
  width: 600px;
  position: absolute;
  /* background-color: yellow; */
}
.baselineDesign {
  width: 600px;
  height: 3px;
  top: 6px;
  box-shadow: 1px 1px grey;
  position: absolute;
  background-color: orange;
  pointer-events: none;
}
.videoStage {
  flex: 1;
  display: flex;
}

.videoHolder {
  width: 80%;
}
.videoWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
