.container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: left;
  position: relative;
}
.leftHolder {
  width: calc(40% - 40px);
  height: calc(100% - 40px);
  display: flex;
  margin: 20px;
  flex: 0;
}

.rightHolder {
  height: calc(100% - 40px);
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 0;
}
.menuHolder {
  height: 20%;
  flex: 0;
}
.sectionHolder {
  height: 80%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin: 0 !important;
}
.sectionTopHolder {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionBottomHolder {
  height: 50%;
  width: 100%;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
}
.image {
  max-height: 100%;
}
ul {
  list-style-type: none;
}

.sectionSingleHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.table {
  width: 80% !important;
}
